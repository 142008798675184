import type { FC } from 'react';
import styles from './Prices.module.scss';
import Content from '#components/shared/ui/Content';
import Typography from '#components/shared/ui/Typography';
import type { WithTranslations } from '#i18n';
import { ConfidentialInfoBlock } from '#components/shared/sections/Prices/ConfidentialInfoBlock.tsx';
import { useMemo, useEffect, useState } from 'react';
import api from '#api/index.ts';
import { pricePackagesConfig } from '#constants/packages.ts';
import type { PricesResponseData, ProductInfo } from '#types/prices.ts';
import PricingPlanCard from '#components/shared/sections/Prices/PricingPlanCard.tsx';
import analytics from '#utils/analytics.ts';
import { getRelativeLocaleUrl } from '#i18n';
import useLocalStorage from '#hooks/useLocalStorage.ts';
import { HOST } from 'CONFIG';
import { COMPLIANCE_HOSTS } from '#constants/compliances.ts';

type Props = {
  locale?: string;
};

const Prices: FC<WithTranslations<Props>> = ({ t, locale }) => {
  const [pricesInfo, setPricesInfo] = useState<PricesResponseData>();
  const [commercialLinkData] = useLocalStorage<object>('_cl', {});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const currentPackage = pricePackagesConfig.trial7;
  const { packages, isDiscounted, discount, vip } = currentPackage ?? {};

  useEffect(() => {
    api.visit
      .prices({ productId: packages, addTrial7: true })
      .then((res) => setPricesInfo(res.data));
  }, []);

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        setIsSubmitDisabled(false);
      }
    };
  }, []);

  const onSubmit = ({ product, funnelPlanTitle }: { product: string; funnelPlanTitle: string }) => {
    setIsSubmitDisabled(true);
    analytics.event({
      category: 'Landing',
      action: 'get_started_trial',
    });
    const data = {
      code: product || 'TRIAL',
      currency: pricesInfo?.fullInfo?.currency || 'USD',
      locale: locale || 'en',
      discount,
    };

    api.billing.checkout(data).then((res) => {
      localStorage.setItem('funnelPlanTitle', funnelPlanTitle);
      localStorage.setItem('productCode', product);
      localStorage.setItem('orderId', res.orderId);
      if (res?.orderId) {
        window.location.assign(getRelativeLocaleUrl(locale, 'checkout'));
      }
    });
  };

  const sortedArray = useMemo(() => {
    const sortedData: Array<[string, ProductInfo]> = [];
    (currentPackage?.order ?? []).forEach((prod) => {
      Object.entries(pricesInfo?.products ?? {}).forEach((card: [string, ProductInfo]) => {
        if (card[0] === prod) {
          sortedData.push(card);
        }
      });
    });
    return sortedData;
  }, [currentPackage, pricesInfo?.products]);
  return !Object.keys(commercialLinkData).length && COMPLIANCE_HOSTS.includes(HOST) ? (
    <Content>
      <div className={styles.head} id="prices">
        <Typography component="h2" variant="h3" color="primary">
          {t.common.prices.chooseYourDeal}
        </Typography>
        <Typography component="p" variant="subtitle1" color="secondary">
          {t.common.prices.getAccessTo}
        </Typography>
        <ConfidentialInfoBlock text={t.common.prices.confidentialSearch} />
      </div>
      <div className={`${styles.flexRow} ${styles.cardsBlock}`}>
        {sortedArray?.map((card: [string, ProductInfo], index: number) => (
          <PricingPlanCard
            t={t}
            key={index}
            card={card[1]}
            product={card[0]}
            fullInfo={pricesInfo?.fullInfo}
            discounted={isDiscounted}
            discount={discount}
            vip={vip}
            isSubmitDisabled={isSubmitDisabled}
            onSubmit={onSubmit}
          />
        ))}
      </div>
      <div className={`${styles.flexRow} ${styles.trialDisclaimerBlock}`}>
        <Typography component="p" variant="capture" color="secondary">
          {t.common.prices.trialDisclaimer}
        </Typography>
      </div>
    </Content>
  ) : (
    <></>
  );
};

export default Prices;
